@use "common/lib/mixins" as *;
@use "theme/variables" as *;
@use "common/lib/functions" as *;

@keyframes show {

    100% {
        opacity: 1;
        transform: none;
    }
}

.c-collection-items {

    .collection-feedback {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $spacer-2;

        .hero {
            align-items: center;
            color: $info-2;
            display: flex;
            flex-direction: column;
            font-size: $font-xl;
            gap: $spacer-1;

            svg {
                @include square($icon-xl);

                color: $info-3;
            }
        }

        .suggest {
            align-items: center;
            display: flex;
            gap: $spacer-1;
            justify-content: center;

            &,
            svg {
                color: $info-2;
                font-style: italic;
            }
        }
    }

    .item {
        display: block;
        margin: 0;
        margin-bottom: $spacer-05;
        position: relative;

        .row-image {
            aspect-ratio: 4/3;
            background: $white;
            box-shadow: $shadow-down-d;
            display: flex;
            height: 75px;
            justify-content: center;
            overflow: hidden;

            img {
                cursor: pointer;
                height: 100%;
            }

            .placeholder {
                color: $grey-4;
                cursor: default;
                height: 100%;
                padding: $spacer-2;
                width: 100%;
            }
        }

        .wrap {
            overflow: unset;
            white-space: normal;
            white-space: wrap;
        }

        &:nth-child(odd) {
            @include row-style($info-6, $info-4, $grey-1);

            .row-edit {
                background: $default-6;
                border-top: 2px dashed $default-4;
                cursor: default;
            }

            .cell-bulk {
                background: $default-5;
            }

            .row-details {
                border-top: 2px dashed $default-4;
            }
        }

        &:nth-child(even) {
            @include row-style($info-5, $info-4, $grey-1);

            .cell-bulk {
                background: $default-4;
            }

            .row-edit {
                background: $default-5;
                border-top: 2px dashed $default-3;
                cursor: default;
            }

            .row-details {
                border-top: 2px dashed $default-3;
            }
        }

        &.is-competitor {
            @include row-style(hs-competitor(5), hs-competitor(5), hs-competitor(1), true);

            border-left: $spacer-025 solid hs-competitor(2);

            &,
            &.selected {
                border-color: hs-competitor(4);
            }

            .td-group .header {
                color: hs-competitor(1);
            }
        }

        &.is-agent-importer-nl {
            @include row-style(hs-agent-nl(4), hs-agent-nl(4), hs-agent-nl(1), true);

            border-left: $spacer-025 solid hs-agent-nl(2);

            &,
            &.selected {
                border-color: hs-agent-nl(3);
            }

            .td-group .header {
                color: hs-agent-nl(1);
            }
        }

        &.is-current-supplier-price-list {
            @include row-style(hs-current-spli(4), hs-current-spli(3), hs-current-spli(1), true);

            &,
            &.selected {
                border-color: hs-current-spli(3);
            }

            .td-group .header {
                color: hs-current-spli(2);
            }
        }

        &.is-msp {
            @include row-style(hs-msi(4), hs-msi(4), hs-msi(1), true);

            border-left: $spacer-025 solid hs-msi(2);

            .td-group .header {
                color: hs-msi(2);
            }
        }

        &.is-current-offer {
            @include row-style(hs-msi(3), hs-msi(3), hs-msi(1), true);

            .td-group .header {
                color: hs-msi(2);
            }
        }

        .cells {
            display: grid;
            position: relative;
            width: 100%;

            .cell {
                align-items: center;
                display: flex;
                min-height: $spacer-6;
                overflow: hidden;
                padding: $spacer-1;
                text-overflow: ellipsis;
                white-space: nowrap;

                .td-group {

                    .header {
                        text-wrap: wrap;
                    }
                }

                &.cell-bulk {
                    padding: 0;
                }

                &.cell-status {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    gap: $spacer-05;
                    justify-content: center;
                    padding: $spacer-1 $spacer-025;

                    .c-icon {
                        @include square($spacer-1 * 2.5);
                    }
                }

                &.cell-group {
                    gap: $spacer-2;
                }

                &.cell-type-disabled {
                    background: $grey-6;
                    color: $grey-3;
                }

                &.cell-type-hidden {
                    display: none;
                }

                &.cell-type-danger {
                    background: $danger-5;
                }

                &.cell-type-warning {
                    background: $warning-5;
                }
            }

            &:hover {

                .cell-actions {
                    opacity: 1;
                }
            }

            .cell-actions {
                align-items: center;
                background: linear-gradient(90deg, transparent, $default-5); // partially hides content beneath
                bottom: 0;
                display: flex;
                gap: $spacer-1;
                height: 100%;
                justify-content: flex-end;
                opacity: 0;
                padding-right: $spacer-1;
                position: absolute;
                right: 0;
                z-index: 100;
            }

            .cell-status {
                align-items: center;
                display: flex;
                flex-direction: column;
                height: 100%;
                z-index: 100;
            }

            .cell-selection {
                display: flex;
                justify-content: center;
            }
        }

        .row-edit {
            align-items: center;
            display: flex;
            gap: $spacer-1;
            justify-content: flex-end;
            padding: $spacer-1;
            width: 100%;

            .field {
                max-width: 200px;
            }

            input {
                max-width: 200px;
            }
        }

        .row-details {
            display: flex;
            flex-direction: column;
            gap: $spacer-2;
            padding: $spacer-1;
            width: 100%;
        }


        &.selected {
            border: $spacer-025 solid $info-3;
            margin-bottom: $spacer-2;
            margin-top: $spacer-2;
            transition: all 0.3s ease;

            > .cells {

                .cell-actions {
                    opacity: 1;
                }
            }
        }

        &.detail:not(.edit) {
            box-shadow: $shadow-down-d-l;
            margin-bottom: $spacer-4;
            transition: all 0.3s ease;

            > .cells {

                .cell-actions {
                    opacity: 1;
                }
            }
        }

        &.item-type-disabled {

            * {
                opacity: 0.8;
            }

            &:nth-child(odd) {
                @include row-style($grey-5, $grey-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($grey-6, $grey-4, $grey-1);
            }
        }


        &.item-type-info {

            .td-group .header {
                color: $info-2;
            }

            &:nth-child(odd) {
                @include row-style($info-5, $info-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($info-6, $info-4, $grey-1);
            }
        }

        &.item-type-loading {

            .td-group .header {
                color: $grey-2;
            }

            &:nth-child(odd) {
                @include row-style($grey-5, $grey-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($grey-6, $grey-4, $grey-1);
            }

            .cell-actions {
                background: linear-gradient(90deg, transparent, $grey-4);
            }
        }

        &.item-type-surface {

            .td-group .header {
                color: $default-2;
            }

            &:nth-child(odd) {
                @include row-style($surface-5, $surface-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($surface-6, $surface-4, $grey-1);
            }

            .cell-actions {
                background: linear-gradient(90deg, transparent, $surface-4);
            }
        }

        &.item-type-success {

            .td-group .header {
                color: $success-2;
            }

            &:nth-child(odd) {
                @include row-style($success-5, $success-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($success-6, $success-4, $grey-1);
            }

            .cell-actions {
                background: linear-gradient(90deg, transparent, $success-4);
            }
        }

        &.item-type-warning {

            .td-group .header {
                color: $warning-2;
            }

            &:nth-child(odd) {
                @include row-style($warning-5, $warning-4, $grey-2);
            }

            &:nth-child(even) {
                @include row-style($warning-6, $warning-4, $grey-1);
            }

            .cell-actions {
                background: linear-gradient(90deg, transparent, $warning-4);
            }
        }

        &.has-row-detail {
            cursor: row-resize;

            .no-click {
                cursor: default;
            }
        }
    }

    &.c-collection-columns {

        .item {
            background: $info-2;
            color: $white;
        }
    }

    .row-separator {
        align-items: center;
        background: $info-5;
        border-left: $spacer-1 solid;
        color: $info-2;
        display: flex;
        font-weight: 600;
        gap: $spacer-1;
        margin-bottom: $spacer-2;
        padding: $spacer-2;
        text-transform: uppercase;

        &:first-child {
            margin-top: 0;
        }

        &:not(:first-child) {
            margin-top: $spacer-4;
        }

        &.type-info {
            background: $info-5;
            border-color: $info-3;
            color: $info-2;
        }

        &.type-warning {
            background: $warning-5;
            border-color: $warning-3;
            color: $warning-2;
        }

        &.type-danger {
            background: $danger-5;
            border-color: $danger-3;
            color: $danger-2;
        }

        &.type-success {
            background: $success-5;
            border-color: $success-3;
            color: $success-2;
        }

        &.type-surface {
            background: $surface-5;
            border-color: $surface-3;
            color: $surface-2;
        }

        &.type-disabled {
            background: $grey-5;
            border-color: $grey-3;
            color: $grey-2;
        }
    }

    .cells {
        @include td-group;


        .td-wrapper {
            align-items: center;
            display: flex;
        }
    }


    &.has-row-status {
        // Make room for an extra cell with row status icons.

        .cell {

            &.cell-actions {
                margin-right: var(--coll-status-width);
            }
        }
    }

    &.has-row-click {

        .item {
            cursor: pointer;
        }
    }

    &.size-s {
        font-size: $font-s;

        .cells {
            font-size: $font-s;
        }
    }
}
